import React, { Component } from 'react';

const teamData = [
    {
        image: "/img/logos/singer.webp",
        alt: "Singer"        
    },
    {
        image: "/img/logos/genxt.webp",
        alt: "GNEXT"
    },
    {
        image: "/img/logos/lumizo.webp",
        alt: "Lumizo"
    },
    {
        image: "/img/logos/bheanbeauty.webp",
        alt: "Bhean Beauty"
    },
    {
        image: "/img/logos/takealot.webp",
        alt: "Take a Lot"
    }
]

class teamOne extends Component {
    state = {        
        data: []
    }
    componentDidMount(){
        this.setState({           
            data: teamData            
        })
    }
    render() {
        return (
            <section id="team" className="section team-area">
                <div className="container">                   
                    <div className="row">
                        {/* Team Slider */}
                        <div className="team-slider owl-carousel">
                            {/* Single Team */}
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`to_${idx}`} className="single-team text-center px-3 py-2 m-2">
                                       
                                        <img loading="lazy" src={item.image} alt={item.alt} width={222} height={80} />
                                       
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default teamOne;