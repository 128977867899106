import React, { Component } from 'react';

const initData = {
    heading: "Features that differentiate Us",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTextTwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const imgData = [
    {
        image: "/img/features/Single-Sign-On.webp",
        title: "Single Sign-On (SSO)"
    },
    {
        image: "/img/features/Abandoned-Cart-Recovery.webp",
        title: "Abandoned Cart Recovery"
    },
    {
        image: "/img/features/Multi-Currency-Support.webp",
        title: "Multi-Currency Support"
    },
    {
        image: "/img/features/Bulk-Updating.webp",
        title: "Bulk Updating"
    },
    {
        image: "/img/features/Product-Variants.webp",
        title: "Product Variants"
    },
    {
        image: "/img/features/Customer-Notifications.webp",
        title: "Customer Notifications"
    }
]

const workData = [
    {
        linkClass: "pager-item active",
        title: "Single Sign-On (SSO)",
        text: "Effortless Sign-In: Use SSO with your social media credentials."
    },
    {
        linkClass: "pager-item",
        title: "Abandoned Cart Recovery",
        text: "Automatically alert cart abandoners to recover potential sales opportunities."
    },
    {
        linkClass: "pager-item",
        title: "Multi-Currency Support",
        text: "Global Shopping Made Easy: Seamlessly shop in your preferred currency."
    },
    {
        linkClass: "pager-item",
        title: "Bulk Updating",
        text: "Quickly edit many product details at once, saving time"
    },
    {
        linkClass: "pager-item",
        title: "Product Variants",
        text: "Find various choices, such as size and color, in one place"
    },
    {
        linkClass: "pager-item",
        title: "Customer Notifications",
        text: "Customize & automate messages with order confirmations"
    }
]

class workOne extends Component {
    state = {
        initData: {},
        data: [],
        workData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: imgData,
            workData: workData
        })
    }
    render() {
        return (
            <section className="section work-area ptb_100 pt-md-0">
                <div className='container'>
                <div className="row justify-content-center">
                        {/* Section Heading */}
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                {/* <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p> */}
                                {/* <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="container position-relative mt-md-5">
                     {/* Work Slider Wrapper */}
                <div className="work-wrapper d-none d-lg-block">
                    <div className="work-slider-wrapper" data-aos="zoom-in">
                        {/* Work Slider */}
                        <ul className="work-slider owl-carousel">
                            {this.state.data.map((item, idx) => {
                                return(
                                    <li key={`wd_${idx}`} className="slide-item">
                                        <img src={item.image} alt={item.title} width={242} height={430} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                    {/* Work Content */}
                    <div className="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`wdo_${idx}`} className="col-12 col-sm-6">
                                    <div className={item.linkClass}>
                                    {/* Single Work */}
                                    <div className="single-work d-inline-block text-center p-4">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default workOne;