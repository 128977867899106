import React, { Component } from "react";
import ScrollupSection from "../scrollupSection/scrollUp";
import Header from "../headerSection/headerTwo";
import BreadcrumbSection from "../breadcrumbSection/breadcrumbOne";
import FooterSection from "../footerSection/footer";

class templates extends Component {
  state = {
    data: null,
    currentPage: 1,
    templatesPerPage: 9 // Adjust the number of templates per page as needed
  };

  componentDidMount() {
    fetch('templatedata/templates.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch templates data');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ data });
      })
      .catch(error => {
        console.error('Error fetching templates data:', error);
      });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { data, currentPage, templatesPerPage } = this.state;
    const templatesDatas = data ? data.templatesDatas : [];

    // Logic to paginate the data
    const indexOfLastTemplate = currentPage * templatesPerPage;
    const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
    const currentTemplates = templatesDatas.slice(
      indexOfFirstTemplate,
      indexOfLastTemplate
    );

    // Logic to generate pagination links
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(templatesDatas.length / templatesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="templates">
        <ScrollupSection />
        <div className="all-area">
          <Header imageData={"/img/logo-white.webp"} />
          <BreadcrumbSection
            heading={"Our Designs"}
            home={"Home"}
            title={"Our Designs"}
          />

          <section id="templates" className="section blog-area ptb_100">
            <div className="container">
              <div className="row">
                {currentTemplates.map((item, idx) => (
                  <div key={`btw_${idx}`} className="col-12 col-md-6 col-lg-4">
                    {/* Single Blog */}
                    <div className="single-blog res-margin mb-4">
                      {/* Blog Thumb */}
                      <div className="blog-thumb">
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          <img loading="lazy" src={item.image} alt={item.title} width={330} height={170}/>
                        </a>
                      </div>
                      {/* Blog Content */}
                      <div className="blog-content p-3">                        
                        {/* Blog Title */}
                        <h3 className="blog-title my-2">
                          <a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>
                        </h3>
                        <p>{item.content}</p>
                        <div className="text-right">
                          <a href={item.link} target="_blank" rel="noopener noreferrer" className="btn btn-bordered mt-3">{item.btnText}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-12">
                   {/* Pagination */}
                   <ul className="pagination justify-content-center">
                    <li className={`px-1 ${currentPage === 1 ? 'disabled' : ''}`}>
                      <a href="#" onClick={currentPage > 1 ? (e) => { e.preventDefault(); this.handlePageChange(currentPage - 1); } : null} aria-label="Previous">
                        <i className="fas fa-arrow-left" />
                      </a>
                    </li>
                     {pageNumbers.map((number) => (
                       <li key={number} className={`px-1 ${currentPage === number ? 'active' : ''}`}>
                        <a href="#" onClick={(e) => { e.preventDefault(); this.handlePageChange(number); }}>{number}</a>
                       </li>
                     ))}
                    <li className={`px-1 ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                      <a href="#" onClick={currentPage < pageNumbers.length ? (e) => { e.preventDefault(); this.handlePageChange(currentPage + 1); } : null} aria-label="Next">
                        <i className="fas fa-arrow-right" />
                      </a>
                    </li>
                   </ul>
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default templates;
