import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footerTwo';


const initData =   
    {    
    "title": "Casper Disrupted the Mattress Industry",
    "image": "/img/stories/casper.webp",    
    "text_1": "Casper launched its eCommerce platform in 2014 & disrupted the mattress industry with a $1.1 billion valuation.",
    "text_2": "The innovative foam mattress products and a smart content marketing strategy helped Casper swiftly make a name and position their place in the mattress industry with the help of online store. Initially, no one expected an eCommerce platform to succeed and convince millions of customers to oder a foam mattress online. Their marketing strategy succeeded at getting potential customers to trust that they’d love a mattress they’d never touched when it arrived on their doorstep.",
    "text_3": "In this breakdown, we’ll analyze how the direct-to-consumer brand was able to build unprecedented trust and convince people to buy mattresses online with SEO as a strategy in Google search engine. The brand opened several physical stores, so they were not a completely online-based business earlier. Even though they still sell mattresses in physical stores, the majority of their growth was accomplished via their online business which is much cost effective compared to the investment on physical stores.",
    } 



class caseStudies02 extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        });
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="blog">
                <ScrollupSection />
                <div className="all-area">
                    <Header imageData={"/img/logo-white.webp"} />
                    <BreadcrumbSection heading={this.state.data.title} home={"Home"} page={"Case Studies"} title={this.state.data.title} />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">  
                            <div className='col-12 col-lg-6'>
                                 {/* Blog Thumb */}
                                 <div className="blog-thumb">
                                            <img src={this.state.data.image} alt={this.state.data.title} />
                                        </div>
                                </div>                             
                                <div className="col-12 col-lg-6">
                                    {/* Single Blog Details */}
                                    <article className="single-blog-details">                                       
                                        {/* Blog Content */}
                                        <div className="blog-content appo-blog">                                           
                                            {/* Blog Details */}
                                            <div className="blog-details">
                                                <h3 className="blog-title py-2 py-sm-3">{this.state.data.title}</h3>
                                                <p className="d-block mb-2">{this.state.data.text_1}</p>
                                                <p className="d-block mb-2">{this.state.data.text_2}</p>                                                
                                                <p className="d-block">{this.state.data.text_3}</p>                                                
                                            </div>
                                        </div>                                        
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default caseStudies02;