import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import CaseStudiesOne from "../components/caseStudies/the-farmers-dog-marketing-strategy";
import CaseStudiesTwo from "../components/caseStudies/casper-disrupted-the-mattress-industry";
import CaseStudiesThree from "../components/caseStudies/urban-outfitters-rewards-program";
import TermsConditions from "../components/termsConditions/terms";
import FaqSection from '../components/faqSection/faqTwo';
import NotFoundPage from "../components/404/errorOne";
import TemplateSection from '../components/templateSection/our-designs';

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} /> 
            <Route path="/caseStudies/the-farmers-dog-marketing-strategy" component={CaseStudiesOne} />
            <Route path="/caseStudies/casper-disrupted-the-mattress-industry" component={CaseStudiesTwo} />
            <Route path="/caseStudies/urban-outfitters-rewards-program" component={CaseStudiesThree} />
            <Route path="/faq" component={FaqSection} />
            <Route path="/terms-and-conditions" component={TermsConditions} />
            <Route path="/our-designs" component={TemplateSection} />
            <Route path="*" component={NotFoundPage} /> 
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;