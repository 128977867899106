import React, { Component } from 'react';
import CounterSection from '../counterSection/counter';

const initData = {
    "headingSpan": "USPs",
    "heading": "Why xCommerz&trade;",
    "headingText": "Elevate your competitive edge with our eCommerce platform, boasting a remarkable array of features & capabilities.",
    "headingText2": "Enjoy full autonomy for your online enterprise with absolutely no vendor lock-in. You have the freedom to select your server, ensuring your complete control. Plus, get your eCommerce site up & running in just 20 days, bypassing the lengthy setup process.",
    "headingText3": "We prioritize transparency in our pricing structure, eradicating hidden costs & transaction fees. This commitment to clarity helps you optimize your ROI. Furthermore, our effortless data migration simplifies the transition from other platforms, ensuring the preservation of vital data.",
    "headingText4": "Our comprehensive suite of built-in features streamlines payment processing, simplifies the creation of personalized storefronts, & elevates user experiences. These attributes not only attract more customers but also foster their loyalty, securing your position at the forefront of the fiercely competitive eCommerce l&scape. With these unique advantages, your online business is set for unparalleled growth & triumph.",
    "featuresData": [
      {
        "id": 1,
        "featureImage": "/img/feature_icon1.webp",
        "featuretitle": "Fee-Free Monthly Usage",
        "featureText": "Use without monthly fees for a budget-friendly eCom."
      },
      {
        "id": 2,
        "featureImage": "/img/feature_icon3.webp",
        "featuretitle": "No Vendor Lock-In",
        "featureText": "Use our eCom without being tied to a single Vendor."
      },
      {
        "id": 3,
        "featureImage": "/img/feature_icon2.webp",
        "featuretitle": "No Transaction Fees",
        "featureText": "keep more of your hard-earned revenue to fuel your growth."
      },
      {
        "id": 4,
        "featureImage": "/img/feature_icon4.webp",
        "featuretitle": "Infinite Design Possibilities",
        "featureText": "Empower your vision with our diverse UX-Driven designs."
      },
      {
        "id": 5,
        "featureImage": "/img/feature_icon5.webp",
        "featuretitle": "SEO-Friendly",
        "featureText": "Are you there when your customers search for you online?"
      }
    ]
  }

class featureOne extends Component {
    state = {
        data: {},
        featuresData: []
    }
    componentDidMount(){
        this.setState({
            data: initData,
            featuresData:initData.featuresData
        })
    }
    render() {
        return (
            <section id="features" className="section features-area ptb_100">
                <div className="shapes-container">
                    <div className="shape bg-shape" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="features-text">
                                {/* Headings */}
                                <div className="headings d-flex align-items-center mb-4">
                                    <span className="d-none d-sm-block">{this.state.data.headingSpan}</span>
                                    <h2>Why xCommerz<sup>™</sup></h2>
                                </div>
                                <p className="my-3">{this.state.data.headingText}</p>
                                <p className="my-3">{this.state.data.headingText2}</p>
                                <p className="my-3">{this.state.data.headingText3}</p>
                                <p className="my-3">{this.state.data.headingText4}</p>
                                {/* Counter List */}
                                <CounterSection />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1 feature-sec">
                            {/* Featured Items */}
                            <div className="featured-items">
                                <ul>
                                    {this.state.featuresData.map((item, idx) => {
                                        return(
                                            <li key={`fo_${idx}`}>
                                                {/* Single Features Item */}
                                                <div className="single-features align-items-center media p-3">
                                                    {/* Features Title */}
                                                    <div className="features-title mr-3">
                                                        <img loading="lazy" src={item.featureImage} alt={item.featuretitle} width={55} height={55} />
                                                    </div>
                                                    {/* Features Text */}
                                                    <div className="features-text media-body">
                                                        <h3>{item.featuretitle}</h3>
                                                        <p>{item.featureText}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default featureOne;