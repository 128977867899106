import React, { Component } from 'react';
import Modal from '../popup/modal';

const initData = {
    btnText: "Request a Quote",
}

class headerTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: initData,
            showModal: false
        };
    }
    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };
    render() {
        return (
            <header className="section header-area">
                <div id="appo-header" className="main-header-area nav-white">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md navbar-light">
                            {/* Logo */}
                            <a className="navbar-brand" href="/">
                                <img className="logo" src={this.props.imageData} alt="xCommerz" width={172} height={50} />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                                <span className="navbar-toggler-icon" />
                            </button>
                            {/* Appo Menu */}
                            <div className="collapse navbar-collapse" id="appo-menu">
                                {/* Header Items */}
                                <ul className="navbar-nav header-items ml-auto">
                                <li className="nav-item">
                                        {!this.state.showModal && (
                                            <button onClick={this.openModal} className="btn btn-bordered mb-4 mb-sm-0 mr-0 mr-md-5">
                                                <span>{this.state.data.btnText}</span>
                                            </button>
                                        )}
                                        <Modal closeModal={this.closeModal} show={this.state.showModal} />                                       
                                    </li>
                                <li className="nav-item">
                                        <a className="nav-link" href="/#home">Home</a>                                        
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#features">Features</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#pricing">Packages</a>
                                    </li>                                   
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#templates">Our Designs</a>                                        
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className="nav-link" href="https://xcommerz.com/blog/">Blog</a>                                       
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/faq">FAQ</a>                                       
                                    </li> */}
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

export default headerTwo;