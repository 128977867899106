import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footerTwo';


const initData = 
    {        
    "title": "Urban Outfitters Rewards Program",
    "image": "/img/stories/urban-outfitters.webp",    
    "text_1": "Urban Outfitters grew from a small Pennsylvania physical shop to a global retail giant in a span of 50 years. They’re fantastic at keeping in touch with Millennial and Gen Z culture, and their message always connects with their audience mainly online.",
    "text_2": "They were able to increase revenue per customer by 146% with their eCommerce platform. They introduced a rewards program and how it strengthens brand loyalty.",
    "text_3": "Unlike many ecommerce store owners, Urban Outfitters was so concerned with driving sales through meaningful human connections. Remember, customer loyalty is just as important as immediate sales revenue and generally leads to more sales in the long term. Urban Outfitters conducted a study on customer loyalty and found that: 86% of customers join loyalty programs for rewards. 78% join for exclusive discounts. 78% of Millennials enroll into two or more loyalty programs. Urban Outfitters knew they had to find a way to strengthen loyalty with their target customers online and a loyalty program seemed like the perfect solution for that.",
    }


class caseStudies03 extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        });
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="blog">
                <ScrollupSection />
                <div className="all-area">
                    <Header imageData={"/img/logo-white.webp"} />
                    <BreadcrumbSection heading={this.state.data.title} home={"Home"} page={"Case Studies"} title={this.state.data.title} />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">  
                            <div className='col-12 col-lg-6'>
                                 {/* Blog Thumb */}
                                 <div className="blog-thumb">
                                            <img src={this.state.data.image} alt={this.state.data.title} />
                                        </div>
                                </div>                             
                                <div className="col-12 col-lg-6">
                                    {/* Single Blog Details */}
                                    <article className="single-blog-details">                                       
                                        {/* Blog Content */}
                                        <div className="blog-content appo-blog">                                           
                                            {/* Blog Details */}
                                            <div className="blog-details">
                                                <h3 className="blog-title py-2 py-sm-3">{this.state.data.title}</h3>
                                                <p className="d-block mb-2">{this.state.data.text_1}</p>
                                                <p className="d-block mb-2">{this.state.data.text_2}</p>                                                
                                                <p className="d-block">{this.state.data.text_3}</p>                                                
                                            </div>
                                        </div>                                        
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default caseStudies03;