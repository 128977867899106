import React, { Component } from 'react';
import ModalAudit from '../popup/modalAudit';

const initData = {
    content: "Transform your business, whether new to e-commerce or looking to revamp an underperforming online store. Let us supercharge your sales. Our xCommerz™ solution is your key to unlocking your full digital potential. Guaranteed!",
    btnText: "Get Your FREE Website Audit Today",
    // btnTextTwo: "Packages",
    welcomeThumb: "/img/welcome_mockup_2.webp",
    iconClass: "icofont-ui-play"
}

class WelcomeOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: initData,
            showModal: false
        };
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        return (
            <section id="home" className="section welcome-area d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="welcome-intro">
                                <h1>Want to Sell Products Down the Street or Around the World?</h1>
                                <h3 className='mt-3'>Let Your Web Do the Selling</h3>
                                <p className="my-3">{this.state.data.content}</p>
                                <div className="button-group d-flex">
                                    {!this.state.showModal && (
                                        <button onClick={this.openModal} className="btn btn-bordered btn-box-sdw mr-3">
                                            <span>{this.state.data.btnText}</span>
                                        </button>
                                    )}
                                    <ModalAudit closeModal={this.closeModal} show={this.state.showModal} />
                                    {/* <a href="#pricing" className="btn btn-bordered d-none d-sm-inline-block scroll">
                                        {this.state.data.btnTextTwo}
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-5 mt-sm-0">
                            <div className="welcome-thumb text-center">
                                <img src={this.state.data.welcomeThumb} alt="Welcome xCommerz" width={540} height={498} />
                            </div>
                            <div className="video-icon d-none d-lg-block">
                                <a className="play-btn" data-fancybox href="/img/video/Xcommerz.mp4">
                                    <i className={this.state.data.iconClass} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WelcomeOne;
