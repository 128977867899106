import React, { Component } from 'react';

const initData = {
    "badgeData": [
      {
        "id": 1,
        "badgeImage": "/img/badges/top_clutch.co_e-commerce_developers_washington_2023.webp"        
      },
      {
        "id": 2,
        "badgeImage": "/img/badges/top_clutch.co_web_design_company_ecommerce_washington.svg"
    },
    {
        "id": 3,
        "badgeImage": "/img/badges/top_clutch.co_e-commerce_developers_sri_lanka_2023.svg"
    },
    {
        "id": 4,
        "badgeImage": "/img/badges/top_clutch.co_seo_company_ecommerce_sri_lanka.svg"
    },
      {
        "id": 5,
        "badgeImage": "/img/badges/top_clutch.co_web_design_company_consumer_products__services_sri_lanka.webp"        
      }
    ]  
   
  }

class badgeOne extends Component {
    state = {
        data: {},
        badgeData: []
    }
    componentDidMount(){
        this.setState({
            data: initData,
            badgeData: initData.badgeData
        })       
    }
    render() {
        return (
            <section id="badges" className="section badges-area">
                <div className="container">
                    <div className="d-flex justify-content-center flex-wrap">
                        {/* badges Item */}
                        {this.state.badgeData.map((item, idx) => {
                            return(
                                <div key={`po_${idx}`} className="col-6 col-md-3 col-lg-2" data-aos="fade-up">
                                    <div className="badges-item text-center p-3">
                                        <div className="badge-img">
                                            <img loading="lazy" src={item.badgeImage} alt="top clutch" width={200} height={200} />
                                        </div>                                        
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default badgeOne;