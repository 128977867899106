import React, { Component } from 'react';

const initData = {
    "ctaData": [
      {
        "linkClass": "phone-num",
        "link": "tel:+94727997711",
        "iconClass": "fa-solid fa-phone",
        "linkText": "(+94) 72 799 7711"      
      },
      {
        "linkClass": "whatsapp",
        "link": "https://wa.me/94727997711",
        "iconClass": "fa-brands fa-whatsapp",
        "linkText": "(+94) 72 799 7711"      
    }
    ]  
   
  }

class ctaSec extends Component {
    state = {
        data: {},
        ctaData: []
    }
    componentDidMount(){
        this.setState({
            data: initData,
            ctaData: initData.ctaData
        })       
    }
    render() {
        return (
            <div className="sticky-icon">
                
                {/* Start Sticky Icon */}
                    
                    {this.state.ctaData.map((item, idx) => {
                            return(
                        <a key={`po_${idx}`} className={item.linkClass} href={item.link}><i className={item.iconClass} /> {item.linkText} </a>
                        );
                    })}
                   
                    {/* End Sticky Icon */}                    
                
            </div>
        );
    }
}

export default ctaSec;