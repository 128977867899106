import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footerTwo';

const initData = 
  {
    "title": "The Farmer's Dog Marketing Strategy",
    "image": "/img/stories/farmers-dog-marketing-strategy.webp",
    "text_1": "The Farmer’s Dog is a direct-to-consumer brand that has made a immense change in the dog food industry. They needed to make overturn on marketing of the, burnt brown balls, which were highly-processed as natural products, so they started creating a different type of dog food in their own category. The transactions on the eCom site, which has the aim of sending dog food directly to customers. The online store consists of different varieties of food & also contains a survey that helps the users decide what type of food and what portion size they should feed their dogs.",
    "text_2": "They are now delivering millions of meals monthly, with a total revenue of over $100M via eCommerce platform and the brand has grown by 669% in recent years, since 2018.",
    "text_3": "Make Personalization a critical main factor in your business. A seamless customer journey is an expectation of today’s online shoppers, which helps them find and buy products that suit their needs in a quick and efficient way. Because of that, most brands tend to build their eCommerce platforms on customer experience with personalization.",
  }

class caseStudies01 extends Component {
  state = {
    data: {}
}
componentDidMount(){
    this.setState({
        data: initData
    });
    window.scrollTo(0, 0);
}

  render() {
    return (
      <div className="blog">
        <ScrollupSection />
        <div className="all-area">
          <Header imageData={"/img/logo-white.webp"} />
          <BreadcrumbSection heading={this.state.data.title} home={"Home"} page={"Case Studies"} title={this.state.data.title} />
          <section id="blog" className="section blog-area ptb_100">
            <div className="container">
              <div className="row">
                <div className='col-12 col-lg-6'>
                  {/* Blog Thumb */}
                  <div className="blog-thumb">
                    <img src={this.state.data.image} alt={this.state.data.title} />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  {/* Single Blog Details */}
                  <article className="single-blog-details">
                    {/* Blog Content */}
                    <div className="blog-content appo-blog">
                      {/* Blog Details */}
                      <div className="blog-details">
                        <h3 className="blog-title py-2 py-sm-3">{this.state.data.title}</h3>
                        <p className="d-block mb-2">{this.state.data.text_1}</p>
                        <p className="d-block mb-2">{this.state.data.text_2}</p>
                        <p className="d-block">{this.state.data.text_3}</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default caseStudies01;
