import React, { useEffect } from 'react';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

function App() {
  useEffect(() => {
    const handlePopstate = () => {      
      window.location.reload();
    };    
    window.addEventListener('popstate', handlePopstate);
    return () => {      
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;