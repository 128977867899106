import React, { Component } from 'react';
import { Link } from "react-router-dom" ; // Import Link

const initData = {
    "heading": "Case Studies & Success Stories",      
    "blogData": [
      {
        "id": 1,
        "link": "/caseStudies/the-farmers-dog-marketing-strategy", 
        "image": "/img/stories/farmers-dog-marketing-strategy.webp",        
        "title": "The Farmer's Dog Marketing Strategye",
        "content": "The Farmer’s Dog is a direct-to-consumer brand that has made a immense change in the dog food industry.",
        "btnText": "Read More",
              },
      {
        "id": 2,
        "link": "/caseStudies/casper-disrupted-the-mattress-industry",
        "image": "/img/stories/casper.webp",        
        "title": "Casper Disrupted the Mattress Industry",
        "content": "Casper launched its eCommerce platform in 2014 & disrupted the mattress industry with a $1.1 billion valuation.",
        "btnText": "Read More",
              },
      {
        "id": 3,
        "link": "/caseStudies/urban-outfitters-rewards-program",
        "image": "/img/stories/urban-outfitters.webp",        
        "title": "Urban Outfitters Rewards Program",
        "content": "Urban Outfitters grew from a small Pennsylvania physical shop to a global retail giant in a span of 50 years.",
        "btnText": "Read More",
              }
    ]
  }
class CaseStudies extends Component {
  state = {
    data: {},
    blogData: [],
  };

  componentDidMount() {
    this.setState({
      data: initData,
      blogData: initData.blogData,
    });
  }

  render() {
    return (
      <section id="blog" className="section blog-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{this.state.data.heading}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.blogData.map((item, idx) => {
              return (
                <div key={`bo_${idx}`} className="col-12 col-md-6 col-lg-4">
                  <div className="single-blog wow fadeIn res-margin" data-wow-duration="2s">
                    <div className="blog-thumb">
                      {/* Use Link with dynamic route */}
                      <Link to={`${item.link}`} aria-label={item.title}>
                        <img loading="lazy" src={item.image} alt={item.title} width={350} height={350} />
                      </Link>                      
                    </div>
                    <div className="blog-content p-4">
                      <h3 className="blog-title my-3">
                        {/* Use Link with dynamic route */}
                        <Link to={`${item.link}`} aria-label={item.title}>{item.title}</Link>
                      </h3>
                      <p>{item.content}</p>
                      {/* Use Link with dynamic route */}
                      <Link to={`${item.link}`} className="blog-btn mt-3" aria-label={item.title}>
                        {item.btnText}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default CaseStudies;
