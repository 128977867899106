import React, { Component } from 'react';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbOne';
import CtaStiky from '../ctaSection/cta';
import FooterSection from '../footerSection/footerTwo';

// const initData = {
//     "heading": "Frequently Asked Questions",
//     // "headingText": "Your inquiries are valuable. Contact us, and we'll promptly get back to you."  
//   };

const tabData = [
    {
        id: 1,
        link: "faq-tab1",
        text: "For Experienced E-commerce Users"
    },
    {
        id: 2,
        link: "faq-tab2",
        text: "For Newcomers to E-commerce"
    }
];

const tabContent = [    
    {
        id: "faq-tab1",
        faqs: [
            {
                id: 1,
                faq: "1. How can xCommerz™ help my small business increase online sales?",
                answer: "xCommerz™ is designed to be user-friendly and scalable, making it effortless for small businesses to establish and manage their online stores. We offer a range of tools for inventory management, secure payment processing, and customizable storefronts to attract and convert customers.",
            },
            {
                id: 2,
                faq: "2. Can I integrate xCommerz™ with my existing website or social media accounts?",
                answer: "Yes, xCommerz™ seamlessly integrates with your existing website and popular social media platforms. This integration extends your online reach and connects you with a broader audience.",
            },
            {
                id: 3,
                faq: "3. I already have an e-commerce website with regular visitors. Can xCommerz™ help me transition to a new platform without disrupting my online business?",
                answer: "Certainly! xCommerz™ specializes in seamless migrations for existing e-commerce websites. We recognize the intricacies of transitioning while keeping your business running smoothly. Our expert team ensures minimal downtime, loss-free data migration, and uninterrupted SEO rankings. We've successfully guided numerous businesses through the transition from their current platforms to our tailored solutions, all while ensuring a seamless shopping experience for their valued customers.",
            },
            {
                id: 4,
                faq: "4. Can I maintain the same level of security and compliance with a custom platform?",
                answer: "Absolutely. At xCommerz™, we prioritize security and compliance for our custom e-commerce platforms. We ensure that your online store meets industry standards for security and data protection, with regular updates and monitoring to maintain compliance.",
            },
            {
                id: 5,
                faq: "5. Can xCommerz™ handle high traffic during peak seasons and promotions?",
                answer: "Yes, xCommerz™ is engineered to scale alongside your business. With cloud-based hosting and load balancing, your website can handle spikes in traffic seamlessly, without performance concerns.",
            },
            {
                id: 6,
                faq: "6. How can xCommerz™ support international expansion and multiple languages/currencies?",
                answer: "We offer comprehensive support for multiple languages and currencies, facilitating international expansion for large enterprises. Our platform effortlessly adapts to different regions and markets.",
            },
            {
                id: 7,
                faq: "7. Why should I consider migrating from my existing platform to a custom e-commerce platform?",
                answer: "Migrating to xCommerz™ provides unparalleled flexibility and control over your online store. You'll benefit from tailored features, unique branding opportunities, and advanced customization that may not be attainable on a current hosted platform.",
            },
            {
                id: 8,
                faq: "8. Can you help with the continuous improvement and optimization of my custom e-commerce site?",
                answer: "Absolutely. We offer optimization services to help you analyze user data, pinpoint areas for improvement, and implement enhancements to boost performance and enhance the user experience on your custom platform.",
            },
            {
                id: 9,
                faq: "9. I'm new to e-commerce. How can xCommerz™ help me get started with online selling?",
                answer: "xCommerz™ is tailored for newcomers. We provide step-by-step setup guides and comprehensive support to ensure your successful launch into online selling.",
            },
            {
                id: 10,
                faq: "10. Do I need technical expertise to set up and manage an online store with xCommerz™?",
                answer: "No technical expertise is necessary. xCommerz™ is designed for simplicity, offering a user-friendly interface that allows you to easily create and manage your online store without any coding knowledge.",
            },
            {
                id: 11,
                faq: "11. Can we integrate our existing systems with xCommerz™?",
                answer: "Yes, our eCommerce solution is designed to seamlessly integrate with various business systems, such as ERP, CRM, and accounting software, ensuring efficient data flow.",
            },
            {
                id: 12,
                faq: "12. How do I market my online store and attract customers?",
                answer: "Marketing strategies for your online store may include social media marketing (SEM), search engine optimization (SEO), and we also provide services for SEO and SEM upon request.",
            },
            {
                id: 13,
                faq: "13. How does xCommerz™ handle data migration if I'm moving from another platform?",
                answer: "Migrating to xCommerz™ is a seamless process, guided by experts to ensure a smooth transition. We handle data migration meticulously, preserving all your valuable data.",
            },
            {
                id: 14,
                faq: "14. Can I avoid vendor lock-in with xCommerz™?",
                answer: "Certainly, xCommerz™ empowers you with complete control over your e-commerce solution, eliminating vendor lock-in. You have access to source codes and a flexible CMS, ensuring you have control over your online store's destiny.",
            }
        ]
    },
    {
        id: "faq-tab2",
        faqs: [
            {
                id: 1,
                faq: "1. How can xCommerz™ help me establish a credible online presence, especially if I've only used social media for my products until now?",
                answer: "xCommerz™ offers a robust platform to create an appealing and trustworthy online store, enabling you to transition from a solely social media-based presence.",
            },
            {
                id: 2,
                faq: "2. I've primarily operated physical stores. What value does online selling offer, and how can it complement my existing business?",
                answer: "xCommerz™ facilitates a seamless transition into online selling, allowing you to complement your physical stores and extend your business's reach.",
            },
            {
                id: 3,
                faq: "3. What strategies can help me transition from a traditional business model to a successful e-commerce business?",
                answer: "xCommerz™ provides comprehensive support, including guides, tools, and resources, to assist you in navigating the transition to e-commerce.",
            },
            {
                id: 4,
                faq: "4. What benefits does e-commerce offer over social media for showcasing my products?",
                answer: "With xCommerz™, you can establish an engaging and professional online store, providing a more credible and versatile platform for showcasing your products.",
            },
            {
                id: 5,
                faq: "5. How can xCommerz™ help me avoid excessive spending on opening physical stores while ensuring a credible online presence?",
                answer: "xCommerz™ offers an affordable and accessible alternative to launching physical stores while maintaining credibility and a strong online presence.",
            },
            {
                id: 6,
                faq: "6. What support do you provide for newcomers to the e-commerce world?",
                answer: "xCommerz™ offers step-by-step setup guides, comprehensive support, and a wealth of resources to empower newcomers to succeed in the e-commerce realm.",
            },
            {
                id: 7,
                faq: "7. How do I effectively market my online store and attract customers?",
                answer: "Marketing your online store with xCommerz™ can encompass strategies such as social media marketing (SEM), search engine optimization (SEO), and we are equipped to provide SEO and SEM services to help you attract customers effectively.",
            }
        ]
    },
];

class FAQ extends Component {
    state = {
        activeTab: tabData[0].link,
        // data:initData,
    };
    // Add a property to keep track of whether the first question has been rendered.
    firstQuestionRendered = false;

    handleTabClick = (link) => {
        this.setState({ activeTab: link });
    };

    render() {
        return (
            <div className="faq-section">                
                <div className="all-area">
                    <Header imageData={"/img/logo-white.webp"} />
                    <BreadcrumbSection heading={"Frequently Asked Questions"} home={"Home"} title={"FAQ"} />
                    <section id="faq-sect" className="bg-gray ptb_100">
                    <div className="container">                       
                        <div className="row wrap-feature41-box">
                            <div className="col-lg-12">
                                <ul className="nav vtab f41-tab" id="myTab">
                                {tabData.map((item, idx) => {
                                    return (
                                        <li key={`fo_${idx}`} className="nav-item">
                                            <a
                                                href="#"
                                                className={`nav-link ${item.link === this.state.activeTab ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault(); 
                                                    this.handleTabClick(item.link);
                                                }}
                                            >
                                     
                                                <div>
                                                    <h5 className="m-b-0">{item.text}</h5>
                                                </div>
                                            </a>
                                        </li>
                                    );
                                })}

                                </ul>
                            </div>
                            </div>
                            <div className="row p-sm-5 wrap-feature41-box bg-white">
                            <div className="col-lg-12 ml-auto">
                                <div className="tab-content" id="myTabContent">
                                    {tabContent.map((item, idx) => {
                                        return (
                                            <div
                                                key={`fo_${idx}`}
                                                className={`tab-pane fade ${item.id === this.state.activeTab ? 'show active' : ''}`}
                                                id={item.id}
                                                role="tabpanel"
                                            >
                                                {item.faqs.map((faqItem, faqIdx) => {
                                                    // Check if this is the first question and add "expanded" class if it is.
                                                    const isExpanded = !this.firstQuestionRendered && faqIdx === 0;
                                                    this.firstQuestionRendered = true; // Mark the first question as rendered.

                                                    return (
                                                        <div key={`faq_${faqIdx}`} className={`topic ${isExpanded ? 'expanded' : ''}`}>
                                                            <div className="open">
                                                                <h2 className="question">{faqItem.faq}</h2>
                                                                <span className="faq-t" />
                                                            </div>
                                                            <p className="answer">{faqItem.answer}</p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>  
                    <CtaStiky />          
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default FAQ;

