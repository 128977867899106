import React, { Component } from 'react';

import Slider from "react-slick";


  


class OurTemplates extends Component {
  state = {
    data: null          
  };

  
  componentDidMount() {
    fetch('templatedata/home_templates.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch templates data');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ data });
      })
      .catch(error => {
        console.error('Error fetching templates data:', error);
        // You might want to set an error state here for UI indication
      });
  }

  render() {
    const { data } = this.state;

    const settings = {
      dots: true,
      arrows: false,
      speed: 2500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      pauseOnHover: true,
      pauseOnFocus: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };

    return (
      <section id="templates" className="section blog-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{data ? data.heading : 'Loading...'}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} className='our-design-slider'>
                {data && data.templatesDatas.map((item, idx) => (
                  <div key={item.id || `btw_${idx}`} className="single-blog res-margin mb-4">
                    <div className="blog-thumb">
                      <a href={item.link} target='_blank' rel="noopener noreferrer"><img loading="lazy" src={item.image} alt={item.title} width={330} height={170}/></a>
                    </div>
                    <div className="blog-content p-3">
                      <h3 className="blog-title my-2"><a href={item.link} target='_blank' rel="noopener noreferrer">{item.title}</a></h3>
                      <p>{item.content}</p>
                      <div className='text-right'>
                        <a href={item.link} className="btn btn-bordered mt-3" target='_blank' rel="noopener noreferrer">{item.btnText}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className='row moredesign-btn'>
            <div className='col-12 text-center'>
              <a href='/our-designs' className="btn btn-bordered">
                <span>{data ? data.btnText : 'Loading...'}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OurTemplates;
